import React, { useRef } from 'react'
import logoNegative from '../../../assets/images/logo-negative.png'
import { Card, Col, Row, Table } from 'react-bootstrap';
import { touristTrip } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setProcessPaymentSlide, setTuristicPckg } from '../../../services/registerSlice';

export const TouristTrip = () => {
  const dispatch = useDispatch();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const { registeredData } = useSelector(state => state.registerData)
  const touristpckg = touristTrip[registeredData.country_code === 'HN' ? 'HNL' : 'USD']
  const handleTuristicPckSelected = (pckg) => {
    dispatch(setTuristicPckg({ data: pckg }))
    dispatch(setProcessPaymentSlide({ slide: 3 }))
  }
  const handleNotTuristicTrip = ()=>{
    dispatch(setProcessPaymentSlide({ slide: 3 }))
  }

  return (
    <div className={windowSize.current[0] < 1000 ? 'container-fluid' : 'container'}>
      <Card className='border-0 mt-2 mb-2 pb-2 card-custom-style'>
        <div className="d-flex justify-content-center cices-blue-bgcolor cices-border-bottom-golden rounded-top-2">
          <img src={logoNegative} alt="CICES 2024" id='logoCICESRegister' className='py-3' />
        </div>
        <Card.Body>
          <Row className='d-flex justify-content-center mt-3'>
            <Col md={5}>
              <h1 className='h3 fw-normal pb-1 border-bottom'>Realizar turístico con proposito</h1>
            </Col>
          </Row>
          <Row className='d-flex justify-content-center mt-3'>
            <Col md={5} sm={8} className='mb-4'>
              <div className='cices-packages-card'>
                <div className='cices-turisticPckg-card-header d-flex align-items-center justify-content-center'>
                  <h3 className='h2 text-center'>{touristpckg.name}</h3>
                </div>
                <div className='cices-packages-card-body'>
                  <h3
                    className='mt-4 mb-4 text-center cices-packages-card-amount'
                  >
                    {touristpckg.amount.toLocaleString("en-US")}
                    <span className='fs-6 '>{touristpckg.currency}</span>
                  </h3>
                  <Row>
                    <Col md={12}>
                      <Table borderless>
                        <tbody>
                          {
                            touristpckg.details.map((i, index) => (
                              <tr key={`${index}__detail`}>
                                <td className=''>
                                  <i className="bi bi-check2-circle fs-4 text-color-cices-golden mx-2"></i>
                                </td>
                                <td className=''>
                                  {i}
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <Row className='d-flex justify-content-center mt-3 pb-5'>
                    <Col md={10} sm={11}>
                      <div className="d-grid gap-2">
                        <button className='btn btn-cices fw-bold' onClick={() => handleTuristicPckSelected(touristpckg)}>Seleccionar</button>
                      </div>
                    </Col>
                  </Row>

                </div>
              </div>
            </Col>
            <div className="w-100"></div>
            <Col md={4}>
              <Row className='d-flex justify-content-center mt-3 pb-2'>
                <Col md={12}>
                  <div className="d-grid gap-2">
                    <button className='btn btn-link fw-bold' onClick={handleNotTuristicTrip}>Proceder sin paquete turístico</button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}
