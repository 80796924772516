import React, { useRef } from 'react'
import logoNegative from '../../../assets/images/logo-negative.png'
import { Card, Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { setProcessPaymentSlide, setWayToPay } from '../../../services/registerSlice';

export const WayToPay = () => {
  const dispatch = useDispatch();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const handleWayToPay = (wayToPay) => {
    dispatch(setWayToPay({value: wayToPay}))
    if(wayToPay === 'PT'){
      dispatch(setProcessPaymentSlide({ slide: 4 }))
    }else{
      dispatch(setProcessPaymentSlide({ slide: 5 }))
    }
  }

  return (
    <div className={windowSize.current[0] < 1000 ? 'container-fluid' : 'container'}>
      <Card className='border-0 mt-2 mb-2 pb-2 card-custom-style'>
        <div className="d-flex justify-content-center cices-blue-bgcolor cices-border-bottom-golden rounded-top-2">
          <img src={logoNegative} alt="CICES 2024" id='logoCICESRegister' className='py-3' />
        </div>
        <Card.Body>
          <Row className='d-flex justify-content-center mt-3'>
            <Col md={6}>
              <h1 className='h3 fw-normal pb-1 border-bottom'>Forma de Pago</h1>
            </Col>
          </Row>
          <Row className='d-flex justify-content-center mt-3 mb-5'>
            <Col md={3} className='mt-3'>
              <div className="d-grid gap-2">
                <input
                  type="checkbox"
                  className="btn-check"
                  name="way_to_pay"
                  id="wtp_pt"
                  autoComplete="off"
                  onClick={() => handleWayToPay('PT')}
                />
                <label
                  className="btn btn-outline-primary"
                  htmlFor="wtp_pt"
                > <i className="bi bi-credit-card"></i> Tarjeta de credito/debito</label>
              </div>
            </Col>
            <Col md={3} className='mt-3'>
              <div className="d-grid gap-2">
                <input
                  type="checkbox"
                  className="btn-check"
                  name="way_to_pay"
                  id="wtp_ti"
                  autoComplete="off"
                  onClick={() => handleWayToPay('TI')}
                  // checked={dataForm?.is_honduran === 'N'}
                />
                <label
                  className="btn btn-outline-primary"
                  htmlFor="wtp_ti"
                > <i className="bi bi-bank"></i> Transferencia Internacional</label>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}
