import React from 'react';
import ReactDOM from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../src/assets/styles.css'
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { persistor, store } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { AppRouter } from './routes/AppRouter';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store} >
        <AppRouter />
      {/* <PersistGate loading={null} persistor={persistor}>
      </PersistGate> */}
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
