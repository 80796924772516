import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { catalogsApi } from "../services/catalogsApi";
import registerSlice from "../services/registerSlice";
import { registerApi } from "../services/registerApi";
// import { registerSlice } from "../services/registerSlice";


const rootReducer = combineReducers({
    [catalogsApi.reducerPath]: catalogsApi.reducer,
    [registerApi.reducerPath]: registerApi.reducer,
    registerData: registerSlice
});

// const persistConfig = {
//   key: "root",
//   storage
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  // reducer: persistedReducer,
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [],
      },
    }).concat(
      catalogsApi.middleware,
      registerApi.middleware
    ),
});

// export const persistor = persistStore(store);

setupListeners(store.dispatch);