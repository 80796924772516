import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Row, Spinner, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import logoNegative from '../../../assets/images/logo-negative.png'
import { useGetConferencesQuery } from '../../../services/catalogsApi';
import { ShieldCheck } from 'react-bootstrap-icons';
import { useParams } from 'react-router-dom'
import { PreviewConferencesSelected } from './PreviewConferencesSelected';
import Swal from 'sweetalert2';


const DAY_NAME = {
  1: 'Lunes',
  2: 'Martes',
  3: 'Miercoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sabado',
  7: 'Domingo'
}

const MONTHS_NAME = {
  'January': 'Enero',
  'February': 'Febrero',
  'March': 'Marzo',
  'April': 'Abril',
  'May': 'Mayo',
  'June': 'Junio',
  'July': 'Julio',
  'August': 'Agosto',
  'September': 'Septiembre',
  'October': 'Octubre',
  'November': 'Noviembre',
  'December': 'Diciembre'
}


export const RegisterConferences = () => {
  moment.locale('es')
  // const dispatch = useDispatch();
  // const { registeredData } = useSelector(state => state.registerData)
  const { id } = useParams()
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const { data: conferences, isLoading } = useGetConferencesQuery()
  const [conferencesGroupBy, setConferencesGroupBy] = useState(null)
  const [conferencesDate, setConferencesDate] = useState([])
  const [selectedConferences, setSelectedConferences] = useState([])
  const [showConferencesDetail, setShowConferencesDetail] = useState(false)

  const dateTitle = (strDate) => {
    let objDate = moment(strDate, "YYYY-MM-DD", 'es')
    let dayDate = objDate.format("DD");
    let day = objDate.day()
    let month = objDate.format("MMMM")
    return `${DAY_NAME[day]} ${dayDate} de ${MONTHS_NAME[month]} del ${objDate.year()}`
  }
  useEffect(() => {
    if(!!conferences){
      const conferencesDate = conferences.map(item => ({ ...item,
          date: `${item.year}-${String(item.month).length === 1 && '0'}${item.month}-${item.day}`,
          selected: item.is_optional === 'N' ? true: false 
        }
      ))
      setConferencesDate(conferencesDate)
      const conferencesGroupBy = Object.groupBy(conferencesDate, ({ date }) => date);
      setConferencesGroupBy(conferencesGroupBy)
    }
  }, [conferences, setConferencesGroupBy])

  const filterConferencesByDate = (date) => {
    return conferencesDate.filter(el => el.date === date)
  }

  const handleSelectedConferences = (confSelectedItem, action) => {
    let confDate = conferencesDate
    confDate = confDate.map(el => {
      if(el.id === confSelectedItem.id) return {...el, selected: action}
      else return el
    })
    setConferencesDate(confDate)
  }

  const handleFinishInscription = () => {
    const selected = conferencesDate.filter(el => el.selected === true)
    if(selected.length > 0){
      setSelectedConferences(selected)
      setShowConferencesDetail(true)
    }else{
      Swal.fire(
        'Selecciona las conferencias de tu interes', 
        `No se selecciono ninguna conferencia,
        por favor selecciona las conferencias de tu interes para inscribirte en ellas`, 
        'error'
      )
    }

  }
  

  if (isLoading) {
    return <div className='d-flex justify-content-center align-items-center'>
      <p>Obteniendo paquetes. por favor espere <Spinner /></p>
    </div>
  }

  if(showConferencesDetail){
    return <PreviewConferencesSelected selectedConferences={selectedConferences} setShowConferencesDetail={setShowConferencesDetail}/>
  }

  return (
    <div className={windowSize.current[0] < 1000 ? 'container-fluid' : 'container'}>
      <Card className='border-0 mt-2 mb-2 pb-2 card-custom-style'>
        <div className="d-flex justify-content-center cices-blue-bgcolor cices-border-bottom-golden rounded-top-2">
          <img src={logoNegative} alt="CICES 2024" id='logoCICESRegister' className='py-3' />
        </div>
        <Card.Body>
          <Row className='d-flex justify-content-center mt-3'>
            <Col md={11}>
              <h1 className='h3 fw-normal pb-1 border-bottom'>Calendario de conferencias</h1>
            </Col>
          </Row>
          <Row className='d-flex justify-content-center mt-5'>
            {
              !!conferencesGroupBy && Object.keys(conferencesGroupBy).map((elDate, index) => (
                <Col md={11} className='mb-4' key={`dateTitle_${index}${index}`}>
                  <h3 className='h5 fw-normal pb-1' key={`h3Inscription${index}${index}`}>{dateTitle(elDate)}</h3>
                  <Table responsive size='sm' hover style={{ 'fontSize': '1rem' }} key={`tableInscription_${index}${index}`}>
                    <tbody key={`tbodyInscription${index}$${index}`}>
                      {
                        filterConferencesByDate(elDate).map((i, idx) => (
                          <>
                            {
                              idx === 0 &&
                              <tr key={`trheader_${index}${idx}`} >
                                <th className='border-bottom'>Conferencia</th>
                                <th className='border-bottom'>Inicio</th>
                                <th className='border-bottom'>Fin</th>
                                <th className='border-bottom'>Salón</th>
                                <th className='border-bottom'>Cupos</th>
                                <th className='border-bottom'></th>
                              </tr>
                            }
                            <tr key={`TRbody_${index}$${idx}`}>
                              <td width={'30%'}>{i.name}</td>
                              <td width={'15%'}>{moment(i.start_at).format("HH:mm:a")}</td>
                              <td width={'15%'}>{moment(i.end_at).format("HH:mm:a")}</td>
                              <td width={'25%'}>{i.conference_room}</td>
                              <td width={'5%'}>{i.num_space_available}</td>

                              <td>
                                {
                                  
                                  i.selected
                                  ? <button
                                      className='btn btn-sm btn-danger'
                                      onClick={()=> handleSelectedConferences(i, false)}
                                      disabled={i.is_optional === 'N'}
                                    >
                                      {
                                        i.is_optional === 'N' 
                                        ? 'Conferencia magistral'
                                        : <><i className="bi bi-x-lg"></i> Quitar</>  
                                      }
                                    </button> 
                                  : i.num_space_available > 0 ?
                                    <button
                                      className='btn btn-cices btn-sm fw-bold'
                                      onClick={() => handleSelectedConferences(i, true)}
                                    >Seleccionar</button>
                                  : <>No disponible</>
                                }
                                </td>
                            </tr>
                          </>
                        ))
                      }
                    </tbody>
                  </Table>
                </Col>
              ))
            }
            <Col md="5">
              <div className="d-grid gap-2 mt-4">
                <button
                  type="button"
                  onClick={handleFinishInscription}
                  className="btn btn-cices-full-blue p-3 fw-bold"
                ><ShieldCheck />  FINALIZAR
                </button>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}
