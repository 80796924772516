export  const touristTrip = {
  "HNL": {
    name: 'Paquete turísmo con proposito',
    amount: 0,
    currency: 'HNL',
    details: [
      "Lorem ipsum dolor sit amet, consectetur adipisicing.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing."
    ]
  },
  "USD": {
    name: 'Paquete turísmo con proposito',
    amount: 400,
    currency: 'USD',
    details: [
      "Lorem ipsum dolor sit amet, consectetur adipisicing.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing."
    ]
  }
}


export const ORGANIZATION_TYPE = {
  'CAC': 'Cooperativa de ahorro y crédito',
  'CDP': 'Cooperativa de producción',
  'OTC': 'Otro tipo de cooperativa',
  'COM': 'Comercio',
  'EMP': 'Emprendimiento',
  'BAN': 'Banco',
  'ONG': 'ONG',
  'OTR': 'Otro'
}

export const ORGANIZATION_TYPE_OPTIONS = [
  {value: 'CAC', label: 'Cooperativa de ahorro y crédito'},
  {value: 'CDP', label: 'Cooperativa de producción'},
  {value: 'OTC', label: 'Otro tipo de cooperativa'},
  {value: 'COM', label: 'Comercio'},
  {value: 'EMP', label: 'Emprendimiento'},
  {value: 'BAN', label: 'Banco'},
  {value: 'ONG', label: 'ONG'},
  {value: 'OTR', label: 'Otro'}
]


export const EMPLOYEE_TYPE = [
  {value: 'C', label: 'Colaborador'},
  {value: 'D', label: 'Delegado'},
  {value: 'J', label: 'Junta Directiva'},
  {value: 'I', label: 'Invitado'},
  {value: 'F', label: 'Conferencista'}
]