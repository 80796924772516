import React from 'react'
import Swal from 'sweetalert2'
import { Col, Row, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setSlideInternal } from '../../services/registerSlice'
import { useStartInternalRegisterMutation } from '../../services/registerApi'


export const SlideFiveInternal = () => {
  const dispatch = useDispatch();
  const { dataFormChorotega, packages } = useSelector(state => state.registerData)
  const handleResetSlide = () => {
    dispatch(setSlideInternal({slideInternal: 2}))
  }

  const [trigger, { isLoading }] = useStartInternalRegisterMutation()

  const handleSubmit = async () => {
    try {
      const datenow = new Date().getTime()
      const typePckg = dataFormChorotega.type_employee === 'J' ? 'A' : 'B'
      const packageSelected = packages.find(item => item.currency === 'HNL' && item.type === typePckg)

      let data = { ...dataFormChorotega, ...{'in_stand_by_data': {
        "date": datenow,
        "currency": "HNL",
        "package_id": packageSelected.id,
        "amount_total": packageSelected.amount,
        "amount_package": packageSelected.amount,
        "turistic_package_id": null,
        "amount_turistic_package": null
      }}}
      await trigger(data).unwrap()
      Swal.fire({
        title: "¡Excelente!",
        text: "¡Registro realizado existosamente!",
        icon: "success"
      });

    } catch (error) {
      alert('Algo salio mal!')
      console.log(error);
    }
  }

  return (
    <>
      <Row className='d-flex justify-content-center mt-3'>
        <Col md={5}>
          <h1 className='fw-normal h6'>
            <span className='border-bottom border-primary border-2 pb-1'>Preview de información ingresada</span>
          </h1>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center mt-3'>
        <Col md={5}>
          <Table>
            <tbody>
              <tr>
                <th>Num. Documento:</th>
                <td>{dataFormChorotega.identity}</td>
              </tr>
              <tr>
                <th>Nombre:</th>
                <td>{dataFormChorotega.name}</td>
              </tr>
              <tr>
                <th>Apellido:</th>
                <td>{dataFormChorotega.last_name}</td>
              </tr>
              <tr>
                <th>Genero:</th>
                <td>{dataFormChorotega.gender === 'M' ? 'Masculino' : 'Femenino'}</td>
              </tr>
              <tr>
                <th>País:</th>
                <td>{dataFormChorotega.country_name}</td>
              </tr>
              <tr>
                <th>Departamento / Provincia:</th>
                <td>{dataFormChorotega.state_name}</td>
              </tr>
              <tr>
                <th>Ciudad:</th>
                <td>{dataFormChorotega.city}</td>
              </tr>
              <tr>
                <th>Celular:</th>
                <td>{dataFormChorotega.phone_code} {dataFormChorotega.phone}</td>
              </tr>
              <tr>
                <th>Correo:</th>
                <td>{dataFormChorotega.email}</td>
              </tr>
              <tr>
                <th>Tipo de organización:</th>
                <td>{dataFormChorotega.organization_type_name}</td>
              </tr>
              <tr>
                <th>Tipo de empleado:</th>
                <td>{dataFormChorotega.employee_type_name}</td>
              </tr>
              <tr>
                <th>Nombre organización:</th>
                <td>{dataFormChorotega.organization_name}</td>
              </tr>
              <tr>
                <th>Puesto:</th>
                <td>{dataFormChorotega.position}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <div className="w-100"></div>
        <Col md={5} className='mt-3'>
          <div className="d-grid gap-2">
            <button
              className="btn btn-primary"
              type="button"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {
                isLoading ? 'Espere... ' : 'Guardar registro '
              }
              <i className="bi bi-check2-circle"></i>
            </button>
          </div>
        </Col>
        <div className="w-100"></div>
        <Col md={5} className=''>
          <div className="d-grid gap-2">
            <button
              className="btn btn-link"
              onClick={handleResetSlide}
              type="button"
            >
              Editar antes de guardar
            </button>
          </div>
        </Col>
      </Row>
    </>
  )
}
