import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { setDataForm, setDataFormChorotega, setSlide, setSlideInternal } from '../../services/registerSlice';
import { EMPLOYEE_TYPE } from '../../utils/constants';

 
export const SlideFour = () => {
  const dispatch = useDispatch();

  const { dataForm, organizations, dataFormChorotega, isInternalRegister, withReset } = useSelector(state => state.registerData);
  const [organizationType, setOrganizationType] = useState([])

  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    if (isInternalRegister) {
      Object.keys(dataFormChorotega).forEach((item) => {
        setValue(item, dataFormChorotega[item])
      })
    }
    else if (withReset) {
      Object.keys(dataForm).forEach((item) => {
        setValue(item, dataForm[item])
      })
    }
  }, [dataForm, setValue, dataFormChorotega, isInternalRegister, withReset])

  useEffect(() => {
    let options = organizations.map(item => ({ value: item.code, label: item.name }))
    setOrganizationType(options)
  }, [organizations])

 
  const onSubmit = async (data) => {
    let values = {}
    
    if(isInternalRegister){
      const employee_type_name = EMPLOYEE_TYPE.find(el => el.value === data.type_employee)
      values = {employee_type_name: employee_type_name.label, ...dataFormChorotega, ...data}
      dispatch(setDataFormChorotega({values}))
      dispatch(setSlideInternal({slideInternal: 5}))
    } else {
      values = {...dataForm, ...data}
      dispatch(setDataForm({values}))
      dispatch(setSlide({slide: 5}))
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className='mt-5'>
      <Row className='d-flex justify-content-center'>
        <Col md={5}>
          <h1 className='fw-normal h6'>
            <span className='border-bottom border-primary border-2 pb-1'>Formulario de registro, información organización</span>
          </h1>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center mt-3'>
        <Col md={5}>
          <Form.Group className="mb-3" controlId="formGroupCountry">
            <Form.Label className="mb-0">Tipo organización que representa</Form.Label>
            <Controller
              name='organization_type_id'
              rules={
                { required: 'Tipo organización es requerido' }
              }
              control={control} render={({ field: { onChange, value, ref } }) => (
                <Select inputRef={ref}
                  onChange={val => onChange(val.value)}
                  value={organizationType.filter(c => c.value === value)}
                  options={organizationType}
                  placeholder={`Seleccione tipo organización`}
                  autoFocus={!isInternalRegister}
                  isDisabled={isInternalRegister}
                />
              )} />
            {errors.organization_type_id && <p className="text-danger">{errors.organization_type_id.message}</p>}
          </Form.Group>
        </Col>
        <div className="w-100"></div>
        {
         isInternalRegister && <>
          <Col md={5}>
            <Form.Group className="mb-3" controlId="formGroupCountry">
              <Form.Label className="mb-0">Tipo empleado</Form.Label>
              <Controller
                name='type_employee'
                rules={
                  { required: 'Tipo empleado es requerido' }
                }
                control={control} render={({ field: { onChange, value, ref } }) => (
                  <Select inputRef={ref}
                    onChange={val => onChange(val.value)}
                    value={EMPLOYEE_TYPE.filter(c => c.value === value)}
                    options={EMPLOYEE_TYPE}
                    placeholder={`Seleccione tipo organización`}
                    autoFocus={isInternalRegister}
                  />
                )} />
              {errors.type_employee && <p className="text-danger">{errors.type_employee.message}</p>}
            </Form.Group>
          </Col>
          <div className="w-100"></div>
         </> 
        }
        <Col md={5}>
          <Form.Group className="mb-3" controlId="formGroupCity">
            <Form.Label className="mb-0">Nombre de organización</Form.Label>
            <Form.Control
              type="text"
              readOnly={isInternalRegister}
              placeholder={`Ingrese Nombre de organización (requerido)`}
              {...register("organization_name",
                {
                  required: 'Nombre de organización es requerido',
                  maxLength: 50
                }
              )}
            />
            {errors.organization_name && <p className="text-danger">{errors.organization_name.message}</p>}
          </Form.Group>
        </Col>
        <div className="w-100"></div>
        <Col md={5}>
          <Form.Group className="mb-3" controlId="formGroupCity">
            <Form.Label className="mb-0">Cargo que desempeña</Form.Label>
            <Form.Control
              type="text"
              placeholder={`Ingrese Cargo que desempeña (requerido)`}
              {...register("position",
                {
                  required: 'Cargo que desempeña es requerido',
                  maxLength: 50
                }
              )}
            />
            {errors.position && <p className="text-danger">{errors.position.message}</p>}
          </Form.Group>
        </Col>
        <div className="w-100"></div>
        <Col md={5} className='mt-3'>
          <div className="d-grid gap-2">
            <button className="btn btn-primary" type="submit">Continuar (4/4) <i className="bi bi-arrow-right"></i></button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}
