import React, { useRef } from 'react'
import { Card, Col, Row, Spinner, Table } from 'react-bootstrap'
import logoNegative from '../../../assets/images/logo-negative.png'
import { ArrowLeft, Check2Circle } from 'react-bootstrap-icons';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useRegisterConferencesMutation } from '../../../services/registerApi';
import Swal from 'sweetalert2';

export const PreviewConferencesSelected = ({ selectedConferences, setShowConferencesDetail }) => {
  moment.locale('es')
  const { id } = useParams()
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [trigger, {isLoading}] = useRegisterConferencesMutation()
  const handleRegisterInscription = async ()=>{
    try {
      const conferences = selectedConferences.map(el => ({conference_id: el.id, registered_id:id}))
      const data = { conferences, id }
      await trigger(data).unwrap()
      Swal.fire(
        'Exito!', 'Registro en conferencias, realizado existosamente!', 'success'
      )

    } catch (err) {
      console.log(err);
      Swal.fire(
        'Oops!', 'Algo no salio como debería!', 'error'
      )

    }
  }

  return (
    <div className={windowSize.current[0] < 1000 ? 'container-fluid' : 'container'}>
      <Card className='border-0 mt-2 mb-2 pb-2 card-custom-style'>
        <div className="d-flex justify-content-center cices-blue-bgcolor cices-border-bottom-golden rounded-top-2">
          <img src={logoNegative} alt="CICES 2024" id='logoCICESRegister' className='py-3' />
        </div>
        <Card.Body>
          <Row className='d-flex justify-content-center mt-3'>
            <Col md={11}>
              <h1 className='h3 fw-normal pb-1 border-bottom'>Detalle de mis conferencias seleccionadas</h1>
            </Col>
          </Row>
          <Row className='d-flex justify-content-center mt-5'>
            <Col md={11} className='mb-4'>
              <Table size='sm' hover style={{ 'fontSize': '1rem' }}>
                <thead>
                  <tr>
                    <th className='border-bottom'>Conferencia</th>
                    <th className='border-bottom'>Inicio</th>
                    <th className='border-bottom'>Fin</th>
                    <th className='border-bottom'>Salón</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    selectedConferences.map((el, idx) => (
                      <tr key={`tbodyInscription${idx}`}>
                        <td width={'30%'}>{el.name}</td>
                        <td width={'15%'}>{moment(el.start_at).format("DD-MM-YYYY HH:mm:a")}</td>
                        <td width={'15%'}>{moment(el.end_at).format("DD-MM-YYYY HH:mm:a")}</td>
                        <td width={'30%'}>{el.conference_room}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </Col>
            <Col md="5">
              <div className="d-grid gap-2 mt-4">
                <button
                  type="button"
                  // onClick={handleFinishInscription}
                  className="btn btn-cices-full-blue p-3 fw-bold"
                  onClick={handleRegisterInscription}
                  disabled={isLoading}
                ><Check2Circle /> { isLoading ? <><Spinner/> Espere </> : 'PROCEDER A REGISTRARME' }
                </button>
              </div>
            </Col>
            <div className="w-100"></div>
            <Col md="5">
              <div className="d-grid gap-2 mt-4">
                <button
                  type="button"
                  // onClick={handleFinishInscription}
                  className="btn btn-link p-3 fw-bold"
                  onClick={()=>setShowConferencesDetail(false)}
                ><ArrowLeft />  Regresar
                </button>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}
