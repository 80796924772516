
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../utils/baseQuery";


export const catalogsApi = createApi({
  reducerPath: "catalogsApi",
  baseQuery: baseQuery,
  tagTypes: ["countries", "conferences", 'packages', 'organizationTypes', 'packagesParent'],

  endpoints: (builder) => ({
    getCountries: builder.query({
      query: () => {
        return {
          url: "/catalogs/countries/",
          method: "GET",
        };
      },
      providesTags: ["countries"],
    }),

    getPackages: builder.query({
      query: (filters) => {
        return {
          url: "/catalogs/packages/",
          method: "GET",
          params: filters
        };
      },
      providesTags: ["packages"],
    }),
    getPackagesParents: builder.query({
      query: (filters) => {
        return {
          url: "/catalogs/packages-parent/",
          method: "GET",
          params: filters
        };
      },
      providesTags: ["packagesParent"],
    }),

    getConferences: builder.query({
      query: (filters) => {
        return {
          url: "/catalogs/conferences/",
          method: "GET",
          params: filters
        };
      },
      providesTags: ["conferences"],
    }),
    getOrganizationType: builder.query({
      query: (filters) => {
        return {
          url: "/catalogs/organization-type/",
          method: "GET",
          params: filters
        };
      },
      providesTags: ["organizationTypes"],
    }),

  }),
});

export const {
    useGetCountriesQuery,
    useGetPackagesQuery,
    useGetConferencesQuery,
    useGetOrganizationTypeQuery,
    useGetPackagesParentsQuery

} = catalogsApi;