import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setPackageSelected, setSlide } from '../../services/registerSlice'


export const SlideFiveUSD = () => {
  const dispatch = useDispatch();
  const { packages, packageSelected } = useSelector(state => state.registerData)
  const [packageAdvanced, setPackageAdvanced] = useState(null)

  const handleUpgradePackage = () => {
    dispatch(setPackageSelected({ data: packageAdvanced }))
    dispatch(setSlide({ slide: 6 }))
  }

  const handleContinue = () => {
    dispatch(setSlide({ slide: 6 }))
  }

  useEffect(() => {
    const advanced = packages.find(el => el.currency === packageSelected.currency && el.type === 'A')
    setPackageAdvanced(advanced)

  }, [packageSelected, packages])


  return (
    <>
      <Row className='d-flex justify-content-center mt-3'>
        <Col lg={3} md={4} sm={8} xs={10}>
          <h1 className='fw-normal h6'>
            <span className='border-bottom border-primary border-2 pb-1'>Mejorar tu habitación</span>
          </h1>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center mt-3'>
        <Col lg={3} md={4} sm={8} xs={10} className='mb-4'>
          <div className='cices-packages-card'>
            <div className='cices-packages-card-header d-flex align-items-center justify-content-center'>
              <h3 className='h4 text-white text-center mx-1'>Actualiza a habitación sencilla</h3>
            </div>
            <div className='cices-packages-card-body'>
              <h3
                className='mt-4 mb-4 text-center cices-packages-card-amount'
              >
                +{(packageAdvanced?.amount - packageSelected.amount).toLocaleString("en-US")}
                <span className='fs-6 '>{packageAdvanced?.currency}</span>
              </h3>
              <Row className='d-flex justify-content-center mt-3 pb-5'>
                <Col md={10} sm={11}>
                  <div className="d-grid gap-2">
                    <button className='btn btn-cices fw-bold mx-1' onClick={handleUpgradePackage}>Actualizar</button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <div className="w-100"></div>
        <Col md={4}>
          <Row className='d-flex justify-content-center mt-3 pb-2'>
            <Col md={12}>
              <div className="d-grid gap-2">
                <button className='btn btn-link fw-bold' onClick={handleContinue}>O continuar con habitación doble</button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
