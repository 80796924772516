import React, { useEffect, useRef } from 'react'

import { Card, Col, Row } from 'react-bootstrap'

import logoNegative from '../assets/images/logo-negative.png'
import { useDispatch, useSelector } from 'react-redux';
import { SlideOne } from '../components/register/SlideOne';
import { SlideTwo } from '../components/register/SlideTwo';
import { SlideThree } from '../components/register/SlideThree';
import { SlideFour } from '../components/register/SlideFour';
import { SlideFive } from '../components/register/SlideFive';
import { useGetCountriesQuery, useGetOrganizationTypeQuery, useGetPackagesQuery } from '../services/catalogsApi';
import { Loader } from '../components/common/Loader';
import { setCountries, setOrganizations, setPackages } from '../services/registerSlice';
import { SlideSix } from '../components/register/SlideSix';
import { SlideSeven } from '../components/register/SlideSeven';
import { SlideProcessPayment } from '../components/register/SlideProcessPayment';
import { SlideConfirmInternationalTransaction } from '../components/register/SlideConfirmInternationalTransaction';
import { CartCheckFill } from 'react-bootstrap-icons';
import { SlideFiveUSD } from '../components/register/SlideFiveUSD';

export const StartRegisterPage = () => {
  const dispatch = useDispatch()
  const { slide, packageSelected, packageTuristicSelected, dataForm } = useSelector(state => state.registerData)
  const { data: countries, isLoading } = useGetCountriesQuery();
  const { data: packages, isLoading: isLoadingPackages } = useGetPackagesQuery({is_turistic: 'N'});
  const { data: organizations, isLoading: isLoadingOrganizations } = useGetOrganizationTypeQuery();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    if(!!packages){
      dispatch(setPackages({packages}))
    }
  }, [packages, dispatch])

  useEffect(() => {
    if(!!countries){
      dispatch(setCountries({countries}))
    }
  }, [countries, dispatch])

  useEffect(() => {
    if(!!organizations){
      dispatch(setOrganizations({organizations}))
    }
  }, [organizations, dispatch])

  const setTotalAmount = ()=> {
    let total = 0;
    total = packageSelected?.amount + (!!packageTuristicSelected ? packageTuristicSelected.amount : 0 )
    return total.toLocaleString("es-US")
  }


  if(isLoading || isLoadingPackages || isLoadingOrganizations) return <Loader/>
  return (
    <div className={windowSize.current[0] < 1000 ? 'container-fluid' : 'container'}>
      <Card className='border-0 mt-2 mb-5 pb-3 card-custom-style rounded-top-2'>
        <div className="cices-blue-bgcolor cices-border-bottom-golden div-header-card-cices rounded-top-2">
          <img src={logoNegative} alt="CICES 2024" id='logoCICESRegister' className='py-3' style={{width: '180px'}} />
        </div>
        <Card.Body>
          <Row>
            <Col md={12}>
              <h1 className='h5 text-color-cices-blue text-center'>
              {
                packageSelected && slide > 4 && <span className='pb-2 border-bottom-cices-golden'>
                <CartCheckFill/> Total a pagar: {setTotalAmount()} {packageSelected?.currency}
                </span>
              }
              </h1>
            </Col>
          </Row>
          {
              slide === 1 ? <SlideOne/> 
            : slide === 2 ? <SlideTwo/>
            : slide === 3 ? <SlideThree/>
            : slide === 4 ? <SlideFour/>
            : slide === 5 && dataForm.is_honduran === 'S' ? <SlideFive/>
            : slide === 5 && dataForm.is_honduran === 'N' ? <SlideFiveUSD/>
            : slide === 6 ? <SlideSix/>
            : slide === 7 ? <SlideSeven/>
            : slide === 8 ? <SlideProcessPayment/>
            : <SlideConfirmInternationalTransaction/>
          }
        </Card.Body>
      </Card>
    </div>
  )
}
