import React from 'react'
import { SpinnerLoader } from './Spinner';

export const Loader = () => {
  return (
    <div className='w-100 h-100'>
        <div className="d-flex justify-content-center align-items-center">
            <SpinnerLoader />
        </div>
    </div>
  )
}