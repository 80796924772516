import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

const registerAdapter = createEntityAdapter();

const slice = createSlice({
  name: "register", 
  initialState: registerAdapter.getInitialState({
    slide: 1,
    slideInternal: 2,
    withReset: false,
    isInternalRegister: false,
    packages: [],
    countries: [],
    organizations: [],
    dataForm: {
      is_honduran: null,
      identity: null,
      name: null,
      last_name: null,
      gender: null,
      country_code: null,
      country_name: null,
      state_code: null,
      state_name: null,
      city: null,
      email: null,
      phone_code: null,
      phone: null,
      organization_name: null,
      organization_type_id: null,
      position: null,
      payment_type: null,
    },

    dataFormChorotega: {
      is_honduran: 'S',
      identity: null,
      name: null,
      last_name: null,
      gender: null,
      country_code: 'HN',
      country_name: 'Honduras',
      state_code: null,
      state_name: null,
      city: null,
      email: null,
      phone_code: '504',
      phone: null,
      organization_name: 'Cooperativa Chorotega',
      organization_type_id: 'CAC',
      organization_type_name: 'Cooperativa de ahorro y crédito',
      position: null,
      payment_type: 'CH' ,
    },
    packageSelected: null,
    packageTuristicSelected: null,

    registeredData: null,
    processPaymentSlide: 1,
    turisticPckg: null,
    way_to_pay: null
  }),
  reducers: {
    setSlide: (state,{ payload: { slide } }) => {
      state.slide = slide;
    },
    setSlideInternal: (state,{ payload: { slideInternal } }) => {
      state.slideInternal = slideInternal;
    },
    setIsInternalRegister: (state,{ payload: { value } }) => {
      state.isInternalRegister = value;
    },
    setPackages: (state,{ payload: { packages } }) => {
      state.packages = packages;
    },
    setCountries: (state,{ payload: { countries } }) => {
      state.countries = countries;
    },
    setOrganizations: (state,{ payload: { organizations } }) => {
      state.organizations = organizations;
    },
    setProcessPaymentSlide: (state,{ payload: { slide } }) => {
      state.processPaymentSlide = slide;
    },
    setDataForm: (state,{ payload: { values } }) => {
      state.dataForm = values;
    },
    setDataFormChorotega: (state,{ payload: { values } }) => {
      state.dataFormChorotega = values;
    },
    setRegisteredData: (state,{ payload: { data } }) => {
      state.registeredData = data;
    },
    setPackageSelected: (state,{ payload: { data } }) => {
      state.packageSelected = data;
    },
    setPackageTuristicSelected: (state,{ payload: { data } }) => {
      state.packageTuristicSelected = data;
    },
  
    setTuristicPckg: (state,{ payload: { data } }) => {
      state.turisticPckg = data;
    },
    setWithReset: (state,{ payload: { value } }) => {
      state.withReset = value;
    },
    setWayToPay: (state,{ payload: { value } }) => {
      state.way_to_pay = value;
    }
  }
});

export const {
  setSlide,
  setDataForm,
  setRegisteredData,
  setPackageSelected,
  setPackageTuristicSelected,
  setProcessPaymentSlide,
  setPackages,
  setOrganizations,
  setCountries,
  setTuristicPckg,
  setWithReset,
  setWayToPay,
  setIsInternalRegister,
  setSlideInternal,
  setDataFormChorotega
} = slice.actions;

export default slice.reducer;