import React, { useRef } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap';
import logoNegative from '../assets/images/logo-negative.png'
import { useGetRegisterSurveyQuery, useRegisteredSurveySaveMutation } from '../services/registerApi';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loader } from '../components/common/Loader';
import { CheckCircle, ExclamationDiamond } from 'react-bootstrap-icons';
import { useForm } from 'react-hook-form';
import { SpinnerLoader } from '../components/common/Spinner';
import Swal from 'sweetalert2';

export const Survey = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const { data, isLoading, error } = useGetRegisterSurveyQuery(id);
  const [trigger, {isLoading: isPosting}] = useRegisteredSurveySaveMutation()


  const onSubmit = async (data) => {
    try {
      let newdata = data.survey.map(item =>{
        let temp = item.score.split('||')
        return {...item, ...{score: temp[0], answer: temp[1]}}
      })
      await trigger([newdata, id]).unwrap();
      Swal.fire(
        '¡Excelente!', 
        `Muchas gracias por darnos tu opinión`, 
        'success'
      )
    } catch (err) {
      console.log(err);
    }
  }

  if (isLoading) return <Loader />

  return (
    <div className={windowSize.current[0] < 1000 ? 'container-fluid' : 'container'}>
      <Card className='border-0 mt-2 mb-5 pb-3 card-custom-style rounded-top-2'>
        <div className="cices-blue-bgcolor cices-border-bottom-golden div-header-card-cices rounded-top-2">
          <img src={logoNegative} alt="CICES 2024" id='logoCICESRegister' className='py-3' />
        </div>
        <Card.Body>
          {
            error?.status === 400 ?
              <h3 className='text-center h2 fw-light mt-4'><ExclamationDiamond className='text-warning' /> {error.data.msg}</h3>
            :
            <Form onSubmit={handleSubmit(onSubmit)} key={'formSurvey'}>
              <Row className='d-flex justify-content-center'>
                <Col md={5} sm={8} xs={12}>
                  <h1 className='text-center h3 fw-normal my-5 cices-border-bottom-golden pb-2'>Encuesta de satisfacción CICES 2024</h1>
                </Col>
                <div className="w-100"></div>

                {
                  data.map((el, idx) => (
                    <>
                      <input type="hidden" {...register(`survey.${idx}.registered_id`, {value: el.registered_id})}/>
                      <input type="hidden" {...register(`survey.${idx}.survey_id`, {value: el.survey_id})}/>
                      <Col md={5} sm={8} xs={12}>
                        <Form.Group className='mb-5 border-bottom survey-question-container'>
                          <Form.Label className='fs-4'>{el.question}</Form.Label>
                          {
                            el.question_type === 'SU' && el.answers.map((r, i) => (
                              <div className="form-check" key={`survey.${i}.score`}>
                                <input
                                  {...register(`survey.${idx}.score`)}
                                  className="form-check-input"
                                  type="radio"
                                  id={`radio_${el.id}_${r.qualification}`}
                                  value={`${r.qualification}||${r.answer}`}
                                />
                                <label className="form-check-label" htmlFor={`radio_${el.id}_${r.qualification}`}>
                                  {r.answer}
                                </label>
                              </div>
                            ))
                          }
                        </Form.Group>
                      </Col>
                      <div className="w-100"></div>
                    </>
                  ))
                }
                <Col md={5} sm={8} xs={12}>
                  <div className="d-grid gap-2">
                    <button
                      disabled={isPosting}
                      type="submit"
                      className="btn btn-cices p-3 fw-bold"
                    >
                      <CheckCircle className='mb-1'/>  REGISTRAR {isPosting && <SpinnerLoader />}
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          }
        </Card.Body>
      </Card>
    </div>
  )
}
