import React, { useEffect, useState } from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form';
import states from '@pixelpay/sdk-core/lib/assets/states.json';
import Select from 'react-select';
import { setDataForm, setDataFormChorotega, setSlide, setSlideInternal } from '../../services/registerSlice';
import { useDispatch, useSelector } from 'react-redux';


export const SlideThree = () => {
  const dispatch = useDispatch();
  const { dataForm, isInternalRegister, dataFormChorotega, withReset, countries } = useSelector(state => state.registerData)
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm();

  const [countryOptions, setcountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [phoneCodeText, setPhoneCodeText] = useState(null);

  useEffect(() => {
    if (!!countries) {
      setcountryOptions(countries.map(item => ({ value: item.code, label: item.name })))
    }
  }, [countries])

  useEffect(() => {
    if (dataForm.is_honduran === 'S' || (isInternalRegister && dataFormChorotega.is_honduran === 'S')) {
      setValue('country_code', 'HN');
      setPhoneCodeText('504');
      setValue('phone_code', '504');
      const statesCountry = states['HN']
      setStateOptions(Object.keys(statesCountry).map(key => ({ value: key, label: statesCountry[key] })))
    }
  }, [dataForm, dataFormChorotega, isInternalRegister, setValue])

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'country_code') {
        const itemSelected = countries.find(i => i.code === value.country_code)
        setPhoneCodeText(itemSelected.phone_code);
        setValue('phone_code', itemSelected.phone_code)
        const statesCountry = states[value.country_code]
        setStateOptions(Object.keys(statesCountry).map(key => ({ value: key, label: statesCountry[key] })))
      }
    }
    )
    return () => subscription.unsubscribe()
  }, [watch, setPhoneCodeText, setValue, countries])

  useEffect(() => {
    if (isInternalRegister) {
      Object.keys(dataFormChorotega).forEach((item) => {
        setValue(item, dataFormChorotega[item])
      })
    }
    else if (withReset) {
      Object.keys(dataForm).forEach((item) => {
        setValue(item, dataForm[item])
      })
    }
  }, [dataForm, withReset, isInternalRegister, dataFormChorotega, setValue])

  const onSubmit = async (data) => {
    const country = countryOptions.find(item => item.value === data.country_code)
    const state = stateOptions.find(item => item.value === data.state_code)
    let values = {}

    if (isInternalRegister) {
      values = { ...dataFormChorotega, ...data, country_name: country.label, state_name: state.label }
      dispatch(setDataFormChorotega({ values }))
      dispatch(setSlideInternal({ slideInternal: 4 }))
    } else {
      values = { ...dataForm, ...data, country_name: country.label, state_name: state.label }
      dispatch(setDataForm({ values }))
      dispatch(setSlide({ slide: 4 }))
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className='mt-5'>
      <input
        type="hidden"
        {...register("phone_code")}
      />
      <Row className='d-flex justify-content-center'>
        <Col md={5}>
          <h1 className='fw-normal h6'>
            <span className='border-bottom border-primary border-2 pb-1'>Formulario de registro, información contacto</span>
          </h1>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center mt-3'>
        <Col md={5}>
          <Form.Group className="mb-3" controlId="formGroupCountry">
            <Form.Label className="mb-0">País origen</Form.Label>
            <Controller
              name='country_code'
              rules={
                { required: 'País es requerido' }
              }
              control={control} render={({ field: { onChange, value, ref } }) => (
                <Select inputRef={ref}
                  onChange={val => onChange(val.value)}
                  value={countryOptions?.filter(c => c.value === value)}
                  options={countryOptions}
                  placeholder={`Seleccione país origen`}
                  autoFocus
                  isDisabled={dataForm.is_honduran === 'S' || (isInternalRegister && dataFormChorotega.is_honduran === 'S')}
                />
              )} />
            {errors.country_code && <p className="text-danger">{errors.country_code.message}</p>}
          </Form.Group>
        </Col>
        <div className="w-100"></div>
        <Col md={5}>
          <Form.Group className="mb-3" controlId="formGroupCountry">
            <Form.Label className="mb-0">Departamento / Provincia</Form.Label>
            <Controller
              name='state_code'
              rules={
                { required: 'Departamento / Provincia es requerido' }
              }
              control={control} render={({ field: { onChange, value, ref } }) => (
                <Select inputRef={ref}
                  onChange={val => onChange(val.value)}
                  value={stateOptions?.filter(c => c.value === value)}
                  options={stateOptions}
                  placeholder={`Seleccione Departamento / Provincia`}
                />
              )} />
            {errors.state_code && <p className="text-danger">{errors.state_code.message}</p>}
          </Form.Group>
        </Col>
        <div className="w-100"></div>
        <Col md={5}>
          <Form.Group className="mb-3" controlId="formGroupCity">
            <Form.Label className="mb-0">Ciudad</Form.Label>
            <Form.Control
              type="text"
              placeholder={`Ingrese ciudad (requerido)`}
              {...register("city",
                {
                  required: 'Ciudad es requerido',
                  maxLength: 100
                }
              )}
            />
            {errors.city && <p className="text-danger">{errors.city.message}</p>}
          </Form.Group>
        </Col>
        <div className="w-100"></div>

        <Col md={5}>
          <Form.Group className="mb-3" controlId="formGroupPhone">
            <Form.Label className="mb-0">Celular</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="PhoneCodeInput">{phoneCodeText}</InputGroup.Text>
              <Form.Control
                type="tel"
                aria-describedby="PhoneCodeInput"
                placeholder={`Ingrese celular (requerido)`}
                {...register("phone",
                  {
                    required: 'Celular es requerido',
                    maxLength: 15
                  }
                )}
              />
            </InputGroup>
            {errors.phone && <p className="text-danger">{errors.phone.message}</p>}
          </Form.Group>
        </Col>
        <div className="w-100"></div>

        <Col md={5}>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label className="mb-0">Correo electrónico</Form.Label>
            <Form.Control
              type="email"
              placeholder={`Ingrese correo electronico (requerido)`}
              {...register("email",
                {
                  required: 'Correo electronico es requerido',
                  maxLength: 255
                }
              )}
            />
            {errors.email && <p className="text-danger">{errors.email.message}</p>}
          </Form.Group>
        </Col>
        <div className="w-100"></div>
        <Col md={5} className='mt-3'>
          <div className="d-grid gap-2">
            <button className="btn btn-primary" type="submit">Continuar (3/4) <i className="bi bi-arrow-right"></i></button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}
