import React, { useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import countries from '@pixelpay/sdk-core/lib/assets/countries.json';
import states from '@pixelpay/sdk-core/lib/assets/states.json';
import phoneCode from '@pixelpay/sdk-core/lib/assets/formats.json';
import { ArrowLeft, CreditCard, ShieldCheck } from 'react-bootstrap-icons'
import ReactInputMask from 'react-input-mask'
import { SpinnerLoader } from '../common/Spinner'
import { useNavigate } from 'react-router-dom'
import { directSale } from '../../services/pixelpayApi'
import { useProcessPaymentMutation, useRegisteredDeleteMutation, useStartRegisterMutation } from '../../services/registerApi'
import { setSlide, setWithReset } from '../../services/registerSlice'


export const SlideProcessPayment = () => {
  const dispatch = useDispatch()
  const { packageSelected, packageTuristicSelected, dataForm } = useSelector(state => state.registerData)
  const { register, handleSubmit, formState: { errors }, watch, setValue, setError } = useForm();
  const navigate = useNavigate();
  const [isPosting, setIsPosting] = useState(false);
  const [stateSelect, setStateSelect] = useState(states[dataForm.country_code]);
  const [phoneCodeSelected, setPhoneCodeSelected] = useState(null);
  const [insertRegistered] = useStartRegisterMutation()
  const [updateRegistered] = useProcessPaymentMutation()
  const [deleteRegistered] = useRegisteredDeleteMutation()

  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const currentYear = new Date().getFullYear()
  const maxYear = currentYear + 20;
  let years = [];

  for (let i = currentYear; i <= maxYear; i++) {
    years.push(i);
  }
  watch(["country"])

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'country') {
        if (!!value.country) {
          setStateSelect(states[value.country])
          setPhoneCodeSelected(`+${phoneCode[value.country].phone_code}`);
        } else {
          setStateSelect({})
          setPhoneCodeSelected(null)
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    setValue('country', dataForm.country_code);
    setValue('city', dataForm.city);
    setValue('phone', dataForm.phone);
    setValue('email', dataForm.email);
    setValue('amount', packageSelected.amount + (!!packageTuristicSelected ? packageTuristicSelected.amount : 0));
    setValue('cardholder', `${dataForm.name} ${dataForm.last_name}`);
    setValue('state', dataForm.state_code);
  }, [dataForm, packageSelected, setValue, packageTuristicSelected])

  const handleGoBack = () => {
    dispatch(setSlide({ slide: 7 }))
  }

  const handleGoToStart = () => {
    dispatch(setWithReset({ value: true }))
    dispatch(setSlide({ slide: 1 }))
  }

  const onSubmit = async (data) => {
    try {
      setIsPosting(true);
      let dataPost = dataForm
      dataPost = {
        ...dataPost,
        package_id: packageSelected.id,
        turistic_package_id: !!packageTuristicSelected ? packageTuristicSelected.id : null
      }
      const newRegistered = await insertRegistered(dataPost).unwrap()
      let amount = !!packageTuristicSelected ? packageTuristicSelected.real_amount : 0
      amount = amount + packageSelected.real_amount
      data = { correlative: newRegistered.correlative, ...data, amount: amount }
      const res = await directSale(data, packageSelected)
      if (res.success) {
        const dataSave = {
          package_id: packageSelected.id,
          payment_response: res.data,
          id: newRegistered.id,
          turistic_package_id: !!packageTuristicSelected ? packageTuristicSelected.turistic_package_id : null
        }
        await updateRegistered(dataSave).unwrap()
        Swal.fire(
          'Exito!', 'Registro y transacción realizada existosamente!', 'success'
        )
        navigate(`/detail/${newRegistered.id}/`)
      } else {
        Swal.fire(
          'Algo no salió bien!', `${res.message}`, 'error'
        )
        if (!!newRegistered) {
          await deleteRegistered({ id: newRegistered.id }).unwrap()
        }
      }
    } catch (err) {
      if (err.status === 400) {
        Swal.fire(
          'Algo no salió bien!', 'Por favor revisar validación de formulario', 'error'
        )
        let fields = err.data
        let keys = Object.keys(fields)
        for (let key in keys) {
          setError(keys[key], { type: 'custom', message: fields[keys[key]] })
        }
      } else if (err.status === 500) {
        Swal.fire(
          'Algo no salió bien!', 'Error de servidor, por favor intente de nuevo!', 'error'
        )
      }
    }
    finally {
      setIsPosting(false);
    }
  }
  return (
    <>
      <Row className='d-flex justify-content-center mt-3'>
        <Col md={8}>
          <button className='btn btn-link ' onClick={handleGoToStart}> <ArrowLeft /> Ir a inicio de registro</button>
        </Col>
        <Col md={8}>
          <h1 className='h3 fw-normal pb-1 border-bottom'> Procesar pago</h1>
        </Col>
        <div className="w-100"></div>
        <Col md="8" className='mt-3 mb-2'>
          <h3 className='h6'>Detalle de items a pagar</h3>
        </Col>
        <div className="w-100"></div>
        <Col md={4}>
          <Card className='card-pckg-selected border-0 mb-2'>
            <div className='px-2 py-1'>
              <h4 className='h6'>Registro en CICES 2024</h4>
              <p className='h6'>Total a pagar: {packageSelected.amount.toLocaleString("en-US")} {packageSelected.currency}</p>
            </div>
          </Card>
        </Col>
        {
          !!packageTuristicSelected ?
            <Col md={4}>
              <Card className='card-pckg-selected border-0 mb-2'>
                <div className='px-2 py-1'>
                  <h4 className='h6 '>{packageTuristicSelected.name}</h4>
                  <p className='h6'>Total a pagar: {packageTuristicSelected.amount.toLocaleString("en-US")} {packageTuristicSelected.currency}</p>
                </div>
              </Card>
            </Col>
            : <Col md={4}></Col>
        }
      </Row>
      <Row className='d-flex justify-content-center mt-3 mb-5'>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className='justify-content-center'>
            <Col md="8" className='mt-3 mb-2'>
              <h3 className='h6'>Dirección de facturación</h3>
            </Col>
            <div className="w-100"></div>

            <Col md='4' sm='6' xs='12' className='mb-3'>
              <div className="form-floating">
                <select
                  className="form-select"
                  id="selectCountries"
                  aria-label="Selecciona país"
                  {...register('country',
                    {
                      required: 'País es requerido'
                    }
                  )}
                >
                  <option value="">Seleccione País</option>
                  {
                    Object.keys(countries).map(key => (<option key={`country${key}`} value={key}>{countries[key]}</option>))
                  }
                </select>
                <label htmlFor="selectCountries">País</label>
              </div>
              {errors.country && <p className="text-danger">{errors.country.message}</p>}
            </Col>

            <Col md='4' sm='6' xs='12' className='mb-3'>
              <div className="form-floating">
                <select
                  className="form-select"
                  id="selectStates"
                  aria-label="Selecciona estado"
                  {...register('state',
                    {
                      required: 'Estado es requerido',
                      value:{value: dataForm.state_code, label: dataForm.state_name}
                    }
                  )}
                >
                  <option value="">Seleccione Estado</option>
                  {
                    !!stateSelect && Object.keys(stateSelect).map(key => (<option key={`state${key}`} value={key} >{stateSelect[key]}</option>))
                  }
                </select>
                <label htmlFor="selectStates">Estado</label>
              </div>
              {errors.state && <p className="text-danger">{errors.state.message}</p>}
            </Col>
            <div className="w-100"></div>


            <Col md={dataForm.is_honduran === 'N' ? '2' : '4'} sm='6' xs='12' className=' mb-3'>
              <div className="form-floating">
                <Form.Control
                  type='text'
                  id='idCityCustomer'
                  placeholder=''
                  {...register(
                    "city",
                    {
                      required: 'Ingresa el nombre de tu ciudad'
                    }
                  )}
                />
                <label htmlFor="idCityCustomer">Ciudad</label>
              </div>
              {errors.city && <p className="text-danger">{errors.city.message}</p>}
            </Col>
            <Col md='4' sm='6' xs='12' className=' mb-3'>
              <div className="form-floating">
                <Form.Control
                  type='text'
                  id='idAddressCustomer'
                  placeholder=''
                  {...register(
                    "address",
                    {
                      required: 'Ingresa un dirección valida'
                    }
                  )}
                />
                <label htmlFor="idAddressCustomer">Dirección</label>
              </div>
              {errors.address && <p className="text-danger">{errors.address.message}</p>}
            </Col>
            {
              dataForm.is_honduran === 'N' &&
              <Col md='2' sm='6' xs='12' className=' mb-3'>
                <div className="form-floating">
                  <Form.Control
                    type='text'
                    id='idZipCustomer'
                    placeholder=''
                    {...register(
                      "zip",
                      {
                        required: 'Ingresa el codigo postal de tu ciudad'
                      }
                    )}
                  />
                  <label htmlFor="idZipCustomer">Codigo postal</label>
                </div>
                {errors.zip && <p className="text-danger">{errors.zip.message}</p>}
              </Col>
            }
            <div className="w-100"></div>

            <Col md='4' sm='6' xs='12' className='mb-3'>
              <div className='input-group'>
                <span className='input-group-text'>{phoneCodeSelected}</span>
                <div className="form-floating">
                  <Form.Control
                    type='number'
                    id='idPhone'
                    placeholder='Número de telefono'
                    className='form-control'
                    {...register('phone',
                      {
                        required: 'Número de telefono es requerido'
                      }
                    )}
                  />
                  <label htmlFor="idPhone">Número de telefono</label>
                </div>
              </div>
              {errors.phone && <p className="text-danger">{errors.phone.message}</p>}
            </Col>
            <Col md='4' sm='6' xs='12' className=' mb-3'>
              <div className="form-floating">
                <Form.Control
                  type='email'
                  id='idEmailCustomer'
                  placeholder='correo@electroni.co'
                  {...register(
                    "email",
                    {
                      required: true
                    }
                  )}
                />
                <label htmlFor="idEmailCustomer">Correo electronico</label>
              </div>
              {errors.email && <p className="text-danger">{errors.email.message}</p>}
            </Col>
            <div className="w-100"></div>

            <Col md="8" className='mt-5 mb-2'>
              <h3 className='h6'>Detalle de la tarjeta</h3>
            </Col>
            <div className="w-100"></div>

            <Col md='4' sm='6' xs='12' className='mb-3'>
              <div className="input-group">
                <span className='input-group-text'><CreditCard /></span>
                <div className="form-floating">
                  <ReactInputMask
                    type='text'
                    mask={"9999 9999 9999 9999"}
                    maskChar={" "}
                    id='idNumberCard'
                    // alwaysShowMask={true}
                    placeholder='Número de tarjeta'
                    className='form-control'
                    {...register('number',
                      {
                        required: 'Número de tarjeta es requerido'
                      }
                    )}
                  />
                  <label htmlFor="idNumberCard">Número de tarjeta</label>
                </div>
              </div>
              {errors.number && <p className="text-danger">{errors.number.message}</p>}
            </Col>
            <Col md='4' sm='6' xs='12' className='mb-3'>
              <div className="form-floating">
                <Form.Control
                  type='text'
                  id='idCustomerName'
                  placeholder='Juan Perez'
                  {...register(
                    "cardholder",
                    {
                      required: 'Nombre del titular de la tarjeta es requerido'
                    }
                  )}
                />
                <label htmlFor="idCustomerName">Nombre del titular de la tarjeta</label>
              </div>
              {errors.cardholder && <p className="text-danger">{errors.cardholder.message}</p>}
            </Col>
            <div className="w-100"></div>
            <Col md='2' sm='6' xs='12' className='mb-3'>
              <div className="form-floating">
                <select
                  className="form-select"
                  id="selectMonth"
                  aria-label="Seleccione"
                  {...register('expire_month',
                    {
                      required: 'Mes es requerido'
                    }
                  )}
                >
                  <option value="">Seleccione</option>
                  {
                    months.map(item => (<option key={`mesExperira${item}`} value={item}>{item}</option>))
                  }
                </select>
                <label htmlFor="selectMonth">Mes Exp.</label>
              </div>
              {errors.expire_month && <p className="text-danger">{errors.expire_month.message}</p>}
            </Col>
            <Col md='2' sm='6' xs='12' className='mb-3'>
              <div className="form-floating">
                <select
                  className="form-select"
                  id="selectYear"
                  aria-label="Selecciona"
                  {...register('expire_year',
                    {
                      required: 'Año es requerido'
                    }
                  )}
                >
                  <option value="">Seleccione</option>
                  {
                    years.map(item => (<option key={`añoExperira${item}`} value={item}>{item}</option>))
                  }
                </select>
                <label htmlFor="selectYear">Año Exp.</label>
              </div>
              {errors.expire_year && <p className="text-danger">{errors.expire_year.message}</p>}
            </Col>
            <Col md='2' sm='6' xs='12' className=' mb-3'>
              <div className="form-floating">
                <Form.Control
                  type='number'
                  id='idCVVCustomer'
                  placeholder=''
                  {...register(
                    "cvv2",
                    {
                      required: 'cvv es campo requerido'
                    }
                  )}
                />
                <label htmlFor="idCVVCustomer">cvv</label>
              </div>
              {errors.cvv2 && <p className="text-danger">{errors.cvv2.message}</p>}
            </Col>

            <Col md='2' sm='6' xs='12' className='mb-3'>
              <div className="form-floating">
                <Form.Control
                  type='number'
                  id='idAmount'
                  placeholder='Monto'
                  {...register(
                    "amount",
                    {
                      required: 'Monto a pagar',
                      disabled: true
                    }
                  )}

                />
                <label htmlFor="idAmount">Monto {packageSelected.currency}</label>
              </div>
              {errors.amount && <p className="text-danger">{errors.amount.message}</p>}
            </Col>

            <Col md='8' sm='12' xs='12' className='mb-3 mt-2'>
              <div className="d-grid gap-2">
                <button disabled={isPosting} type="submit" className="btn btn-cices p-3 fw-bold"><ShieldCheck />  REALIZAR PAGO {isPosting && <SpinnerLoader />}  </button>
              </div>
            </Col>
            <div className="w-100"></div>
            <Col md={8}>
              <Row className='d-flex justify-content-center mt-3 pb-2'>
                <Col md={12}>
                  <div className="d-grid gap-2">
                    <button className='btn btn-link ' onClick={handleGoBack}> <ArrowLeft /> Volver a forma de pago</button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  )
}
