import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


import { NotFound } from "../views/NotFound";
import { StartRegisterPage } from "../views/StartRegisterPage";
import { RegisterDetail } from "../views/RegisterDetail";
import { StartInternalRegisterPage } from "../views/StartInternalRegisterPage";
import { Survey } from "../views/Survey";


export const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<StartRegisterPage />} />
        <Route exact path="/interno-chorotega/" element={<StartInternalRegisterPage />} />
        <Route exact path="/detail/:id/" element={<RegisterDetail />} />
        <Route exact path="/survey/:id/" element={<Survey />} />

        <Route path="*" element={<NotFound />} />

      </Routes>
    </Router>
  );
};