import React from 'react'
import { Col, ListGroup, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setDataForm, setSlide, setWithReset } from '../../services/registerSlice'
import { ArrowLeft } from 'react-bootstrap-icons'
import { SpinnerLoader } from '../common/Spinner'
import { useNavigate } from 'react-router-dom'
import { useStartRegisterInStandByMutation } from '../../services/registerApi'
import Swal from 'sweetalert2'
import instructionImage from '../../assets/images/instrucciones_transferencia_internacional.jpg'

export const SlideConfirmInternationalTransaction = () => {
  const dispatch = useDispatch()
  const { packageSelected, packageTuristicSelected, dataForm } = useSelector(state => state.registerData);
  const [trigger, {isLoading}] = useStartRegisterInStandByMutation()
  const handleGoBack = () => {
    dispatch(setSlide({ slide: 7 }))
  }

  const handleGoToStart = () => {
    dispatch(setWithReset({ value: true }))
    dispatch(setSlide({ slide: 1 }))
  }

  const handleConfirm = async () => {
    try {
      const now = new Date()
      const data = {
        ...dataForm,
        in_stand_by_data: {
          package_id: packageSelected.id,
          turistic_package_id: !!packageTuristicSelected ? packageTuristicSelected.id : null,
          currency: packageSelected.currency,
          amount_package: packageSelected.amount,
          amount_turistic_package: !!packageTuristicSelected ? packageTuristicSelected.amount : 0,
          amount_total: packageSelected.amount + (!!packageTuristicSelected ? packageTuristicSelected.amount : 0),
          date: now.getTime()
        },
      }
      await trigger(data).unwrap()
      Swal.fire({
        icon: "success",
        title: "Te hemos enviado un correo con las instrucciones.",
        text: "¡Solicitud de pago por transferencia internacional realizada existosamente!",
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonText: 'Ir a inicio del registro',
        footer: '<a href="https://ciceshn.com" class="btn btn-link">Ir a ciceshn.com</a>'
      }).then((result) => {
        dispatch(setWithReset({ value: false }))
        dispatch(setDataForm({ values: null}))
        dispatch(setSlide({ slide: 1 }))
      });
    } catch (err) {
      if (err.status === 400) {
        let fields = err.data
        let keys = Object.keys(fields)
        let htmlString = '<p>'
        for (let key in keys) {
          htmlString += `${fields[keys[key]]} <br/>`
        }
        htmlString += '</p>'
        Swal.fire(
          'Algo no salió bien!', htmlString, 'error'
        )
      } else if (err.status === 500) {
        Swal.fire(
          'Algo no salió bien!', 'Error de servidor, por favor intente de nuevo!', 'error'
        )
      }
    }
  }

  return (
    <>
      <Row className='d-flex justify-content-center mt-3'>
        <Col md={8}>
          <button className='btn btn-link ' onClick={handleGoToStart}> <ArrowLeft /> Ir a inicio de registro</button>
        </Col>
        <Col md={8}> 
          <h1 className='fw-normal h6 mt-2'>
            <span className='border-bottom border-primary border-2 pb-1'>Instrucciones transferencia bancaria internacional</span>
          </h1>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center mt-3'>
        <Col md={8}>
          <img src={instructionImage} alt="Instrucciones" style={{width: '100%'}} />
        </Col>
        <Col md='8' sm='12' xs='12' className='mb-3 mt-5'>
          <div className="d-grid gap-2">
            <button
              disabled={isLoading}
              onClick={handleConfirm}
              className="btn btn-cices p-3 fw-bold"
            >Confirmo {isLoading && <SpinnerLoader />}  </button>
          </div>
        </Col>
        <div className="w-100"></div>
        <Col md={8}>
          <Row className='d-flex justify-content-center mt-3 pb-2'>
            <Col md={12}>
              <div className="d-grid gap-2">
                <button className='btn btn-link' onClick={handleGoBack}> <ArrowLeft /> Volver a forma de pago</button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
