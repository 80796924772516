import { fetchBaseQuery } from "@reduxjs/toolkit/query";

const API_URL = process.env.REACT_APP_API_URL;
const baseQuery = (args, api, extraOptions) =>
  fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Accept", "*/*");
      if (!extraOptions || (extraOptions && !extraOptions.fileUpload)) {
        headers.set("Content-Type", "application/json");
      }

    //   const token = getState().auth.accessToken;
    //   if (token) {
    //     headers.set("authorization", `Bearer ${token}`);
    //   }
      return headers;
    },
  })(args, api, extraOptions);

  export default baseQuery;