
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../utils/baseQuery";


export const registerApi = createApi({
  reducerPath: "registerApi",
  baseQuery: baseQuery,
  tagTypes: ["detail", "survey"],

  endpoints: (builder) => ({
    startRegister: builder.mutation({
      query: (data) => {
        return {
          url: '/registered/create/',
          method: 'POST',
          body: data
        }
      },
    }),
    startInternalRegister: builder.mutation({
      query: (data) => {
        return {
          url: '/registered/create-internal/',
          method: 'POST',
          body: data
        }
      },
    }),
    startRegisterInStandBy: builder.mutation({
      query: (data) => {
        return {
          url: '/registered/create-in-stand-by/',
          method: 'POST',
          body: data
        }
      },
    }),
    getRegisterDetail: builder.query({
      query: (id) => {
        return {
          url: `/registered/${id}/detail/`,
          method: "GET",
        };
      },
      providesTags: ["detail"],
    }),

    moveToStandBy: builder.mutation({
      query: (data) => {
        const { id } = data
        return {
          url: `/registered/${id}/move-to-stand-by-mode/`,
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ["detail"]
    }),
    processPayment: builder.mutation({
      query: (data) => {
        const { id } = data
        return {
          url: `/registered/${id}/process-payment/`,
          method: 'POST',
          body: data
        }
      },
    }),
    registerConferences: builder.mutation({
      query: (data) => {
        const { id } = data
        return {
          url: `/registered/${id}/conference/`,
          method: 'POST',
          body: data.conferences
        }
      },
      invalidatesTags: ["detail"]
    }),
  
    registeredDelete: builder.mutation({
      query: (data) => {
        const { id } = data
        return {
          url: `/registered/${id}/delete/`,
          method: 'POST',
          body: {}
        }
      },
    }),

    getRegisterSurvey: builder.query({
      query: (id) => {
        return {
          url: `/registered/${id}/survey/`,
          method: "GET",
        };
      },
      providesTags: ["survey"],
    }),

    registeredSurveySave: builder.mutation({
      query: (data) => {
        console.log(data);
        return {
          url: `/registered/${data[1]}/survey-save/`,
          method: 'POST',
          body: data[0]
        }
      },
      invalidatesTags: ["survey"]
    }),

  }),
});

export const {
  useStartRegisterMutation,
  useGetRegisterDetailQuery,
  useProcessPaymentMutation,
  useMoveToStandByMutation,
  useRegisterConferencesMutation,
  useRegisteredDeleteMutation,
  useStartRegisterInStandByMutation,
  useStartInternalRegisterMutation,
  useGetRegisterSurveyQuery,
  useRegisteredSurveySaveMutation
} = registerApi;