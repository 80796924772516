import Settings from "@pixelpay/sdk-core/lib/models/Settings";
import Card from "@pixelpay/sdk-core/lib/models/Card";
import Billing from "@pixelpay/sdk-core/lib/models/Billing";
import Order from "@pixelpay/sdk-core/lib/models/Order";
import Item from "@pixelpay/sdk-core/lib/models/Item";
import SaleTransaction from "@pixelpay/sdk-core/lib/requests/SaleTransaction";
import Transaction from "@pixelpay/sdk-core/lib/services/Transaction";
import Helpers from '@pixelpay/sdk-core/lib/base/Helpers'

const urlCallback = process.env.REACT_APP_API_CALLBACK
const endpoint = process.env.REACT_APP_PIXELPAY_ENDPOINT
const key = process.env.REACT_APP_PIXELPAY_KEY
const hash = process.env.REACT_APP_PIXELPAY_HASH
const pixelpay_env = process.env.REACT_APP_PIXELPAY_ENV


export const directSale = async (data, pckg) => {
    const settings = new Settings()
    if (pixelpay_env === 'sandbox'){
        settings.setupSandbox()
    }else{
        settings.setupEndpoint(endpoint)
        settings.setupCredentials(
            key, Helpers.hash('MD5', hash)
        )
    }
    const card = new Card()
    card.number = data.number
    card.cvv2 = data.cvv2
    card.expire_month = data.expire_month
    card.expire_year = data.expire_year
    card.cardholder = data.cardholder

    const billing = new Billing()
    billing.address = data.address
    billing.country = data.country
    billing.state = data.state
    billing.city = data.city
    billing.phone = data.phone
    if(data.zip){
      billing.zip = data.zip
    }

    const item = new Item()
    item.code = `000${pckg.id}-${pckg.currency}`
    item.title = `${pckg.name}-${pckg.currency}`
    item.price = data.amount
    item.qty = 1

    const order = new Order()
    order.id = data.correlative
    order.currency = pckg.real_currency
    order.customer_name = data.cardholder
    order.customer_email = data.email
    order.addItem(item)

    const sale = new SaleTransaction()
    sale.setOrder(order)
    sale.setCard(card)
    sale.setBilling(billing)
    // sale.order_callback = urlCallback

    const service = new Transaction(settings)
    try {
        const response = await service.doSale(sale)
        return response

    } catch (error) {
        console.log(error);
    }
}