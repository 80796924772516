import React, { useRef } from 'react'
import { useGetPackagesQuery } from '../../../services/catalogsApi';
import { Card, Col, Row, Spinner, Table } from 'react-bootstrap';
import logoNegative from '../../../assets/images/logo-negative.png'
import { useDispatch, useSelector } from 'react-redux';
import { setPackageSelected, setProcessPaymentSlide } from '../../../services/registerSlice';
import { ProcessPayment } from './ProcessPayment';
import { TouristTrip } from './TouristTrip';
import { WayToPay } from './WayToPay';
import { InternationalTransferDetail } from './InternationalTransferDetail';

export const PaymentOptions = ({data}) => {
  const dispatch = useDispatch()
  const { processPaymentSlide } = useSelector(state => state.registerData)
  const filters = {currency: data.country_code === 'HN' ? 'HNL': 'USD'}
  const {data: packages, isLoading } = useGetPackagesQuery(filters)
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const handlePackageSelected = (pkg) => {
    dispatch(setPackageSelected({data: pkg}))
    dispatch(setProcessPaymentSlide({ slide: 2 }))
  }

  if (isLoading) {
    return <div className='d-flex justify-content-center align-items-center'>
      <p>Obteniendo paquetes. por favor espere <Spinner/></p>
    </div>
  }

  return (
    <>
      {
        processPaymentSlide === 1 ?
          <div className={windowSize.current[0] < 1000 ? 'container-fluid' : 'container'}>
            <Card className='border-0 mt-2 mb-2 pb-2 card-custom-style'>
              <div className="d-flex justify-content-center cices-blue-bgcolor cices-border-bottom-golden rounded-top-2">
                <img src={logoNegative} alt="CICES 2024" id='logoCICESRegister' className='py-3' />
              </div>
              <Card.Body>
                <Row className='d-flex justify-content-center mt-3'>
                  <Col md={8}>
                    <h1 className='h3 fw-normal pb-1 border-bottom'> Bienvenido (a) {data.name} {data.last_name}</h1>
                  </Col>
                </Row>

                <Row className='d-flex justify-content-center mt-3'>
                  <Col md={8}>
                      <h3 className='h6'>
                        Para continuar por favor selecciona el paquete de tu preferencia
                      </h3>
                  </Col>
                </Row>
                <Row className='d-flex justify-content-center mt-3'>
                  {
                    packages?.map((item, index)=> (
                      <Col md={4} sm={8} key={`${index}__col`} className='mb-4'>
                        <div className='cices-packages-card'>
                          <div className='cices-packages-card-header d-flex align-items-center justify-content-center'>
                            <h3 className='h2 text-white'>{item.name}</h3>
                          </div>
                          <div className='cices-packages-card-body'>
                            <h3
                              className='mt-4 mb-4 text-center cices-packages-card-amount'
                            >
                              {item.amount.toLocaleString("en-US")}
                              <span className='fs-6 '>{item.currency}</span>
                            </h3>
                            <Row>
                              <Col md={12}>
                                <Table borderless>
                                  <tbody>
                                    {
                                      item.details.map((i, index) =>(
                                        <tr key={`${index}__detail`}>
                                          <td className=''>
                                            <i className="bi bi-check2-circle fs-4 text-color-cices-golden mx-2"></i>
                                          </td>
                                          <td className=''>
                                            {i}
                                          </td>
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </Table>
                              </Col>
                            </Row>
                            <Row className='d-flex justify-content-center mt-3 pb-5'>
                              <Col md={10} sm={11}>
                              <div className="d-grid gap-2">
                                <button className='btn btn-cices fw-bold' onClick={()=>handlePackageSelected(item)}>Seleccionar</button>
                              </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    ))
                  }
                </Row>
              </Card.Body>
            </Card>
          </div>
        : processPaymentSlide === 2 ? <TouristTrip/>
        : processPaymentSlide === 3 ? <WayToPay/>
        : processPaymentSlide === 4 ? <ProcessPayment/>
        : processPaymentSlide === 5 ? <InternationalTransferDetail/>
        : <></>
      }
    </>
  )
}