import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import efectivo from '../../assets/images/efectivo.png'
import tarjeta from '../../assets/images/tarjeta.png'
import { setSlide } from '../../services/registerSlice'


export const SlideSeven = () => {
  const dispatch = useDispatch()
  const handleOption = (opt) => {
    if (opt === 'PT') {
      dispatch(setSlide({ slide: 8 }))
    } else {
      dispatch(setSlide({ slide: 9 }))
    }
  }

  return (
    <>
      <Row className='d-flex justify-content-center mt-3'>
        <Col md={6}>
          <h1 className='fw-normal h6'>
            <span className='border-bottom border-primary border-2 pb-1'>Forma de Pago</span>
          </h1>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center mt-3 mb-5'>
        <Col md={3} className='mt-3'>
          <Card className='border-0 mb-5 pb-3 card-custom-style rounded-top-2 btn-cices-full-blue-v2 d-flex justify-content-center'>
            <Card.Img variant="top" src={tarjeta} className='mb-5 pt-5' style={{ width: '35%', alignSelf: 'center' }} />
            <Card.Body className='px-4 pb-4' style={{ marginTop: '15px' }}>
              <div className="d-grid gap-2">
                <Button className='btn-cices-v2 fw-bold' onClick={() => handleOption('PT')}>Tarjeta de credito/debito</Button>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col md={3} className='mt-3'>
          <Card className='border-0 mb-5 pb-3 card-custom-style rounded-top-2 btn-cices-full-blue-v2 d-flex justify-content-center'>
            <Card.Img variant="top" src={efectivo} className='mb-5 pt-5' style={{ width: '35%', alignSelf: 'center' }} />
            <Card.Body className='px-4 pb-4' style={{ marginTop: '15px' }}>
              <div className="d-grid gap-2">
                <Button className='btn-cices-v2 fw-bold' onClick={() => handleOption('TI')}>Otra forma de pago</Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}
