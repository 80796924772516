import React, { useRef } from 'react'
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom'
import logoNegative from '../../../assets/images/logo-negative.png';
import { useMoveToStandByMutation } from '../../../services/registerApi';
import { SpinnerLoader } from '../../common/Spinner';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

export const InternationalTransferDetail = () => {
  const { id } = useParams()
  const nagivate = useNavigate()
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [trigger, { isLoading, isError }] = useMoveToStandByMutation();
  const { registeredData, packageSelected, turisticPckg } = useSelector(state => state.registerData);

  const handleConfirm = async () => {
    try {
      const now = new Date()
      const data = {
        in_stand_by_data: {
          package_id: packageSelected.id,
          amount_package: packageSelected.amount,
          currency: packageSelected.currency,
          trip: !!turisticPckg ? 'S' : 'N',
          amount_trip: !!turisticPckg ? turisticPckg.amount : 0,
          amount_total: packageSelected.amount + (!!turisticPckg ? turisticPckg.amount : 0),
          date: now.getTime()
        },
        id: id
      }
      await trigger(data).unwrap()
      Swal.fire({
        icon: "success",
        title: "Te hemos enviado un correo con las instrucciones.",
        text: "¡Solicitud de pago por transferencia internacional realizada existosamente!",
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        footer: '<a href="https://ciceshn.com" class="btn btn-link">Ir a inicio</a>'
      }).then((result) => {
        nagivate('/')
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={windowSize.current[0] < 1000 ? 'container-fluid' : 'container'}>
      <Card className='border-0 mt-2 mb-2 pb-2 card-custom-style'>
        <div className="d-flex justify-content-center cices-blue-bgcolor cices-border-bottom-golden rounded-top-2">
          <img src={logoNegative} alt="CICES 2024" id='logoCICESRegister' className='py-3' />
        </div>
        <Card.Body>
          <Row className='d-flex justify-content-center mt-3'>
            <Col md={8}>
              <h1 className='h3 fw-normal pb-1 border-bottom'>Instrucciones transferencia internacional</h1>
            </Col>
          </Row>
          <Row className='d-flex justify-content-center mt-5 mb-5'>
            <Col md={8}>
              <ListGroup as="ol" numbered variant="flush">
                <ListGroup.Item as={'li'}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</ListGroup.Item>
                <ListGroup.Item as={'li'}>Nulla orci dolor, faucibus vitae rutrum in, rutrum ac enim.</ListGroup.Item>
                <ListGroup.Item as={'li'}>Praesent placerat finibus mauris ac dignissim.</ListGroup.Item>
                <ListGroup.Item as={'li'}>Nullam commodo eros id augue consequat, vitae rhoncus libero vestibulum.</ListGroup.Item>
                <ListGroup.Item as={'li'}>Aenean eget tortor ut dolor pulvinar placerat. Integer eget mattis massa, ut consectetur mauris.</ListGroup.Item>
                <ListGroup.Item as={'li'}>Nam at urna lacus. Suspendisse quis velit libero. Suspendisse luctus ante vel euismod aliquam.</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md='8' sm='12' xs='12' className='mb-3 mt-5'>
              <div className="d-grid gap-2">
                <button
                  disabled={isLoading}
                  onClick={handleConfirm}
                  className="btn btn-cices p-3 fw-bold"
                >Confirmo, realizaré transferencia {isLoading && <SpinnerLoader />}  </button>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}
