import React, { useEffect } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import nacional from '../../assets/images/nacional.png'
import internacional from '../../assets/images/internacional.png'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setDataForm, setPackageSelected, setSlide } from '../../services/registerSlice';


export const SlideOne = () => {
  const { dataForm, dataFormChorotega, isInternalRegister, withReset, packages } = useSelector(state => state.registerData)
  const dispatch = useDispatch();
  const {
    handleSubmit,
    setValue
  } = useForm();
 
  const onSubmit = async (data) => {
    let values = {}
    if(isInternalRegister){
      values = { ...dataFormChorotega, ...data}
    }else{
      values = { ...dataForm, ...data}
    }
    dispatch(setDataForm({values}))
    dispatch(setSlide({ slide: 2 }))
  }

  useEffect(() => {
    if(withReset){
      if(isInternalRegister){
        Object.keys(dataFormChorotega).forEach((item) => {
          setValue(item, dataFormChorotega[item])
        })
      }else{
        Object.keys(dataForm).forEach((item) => {
          setValue(item, dataForm[item])
        })
      }
    }
  }, [dataForm, dataFormChorotega, isInternalRegister, setValue, withReset])

  const handleOption = (opt)=>{
    const currency = opt.is_honduran === 'S' ? 'HNL': 'USD';
    const packagesSelected = packages.find(
      item => item.currency === currency && item.type === 'B' && item.with_accommodation === 'S'
    )
    dispatch(setPackageSelected({data: packagesSelected}))
    const values = { ...dataForm, ...opt}
    dispatch(setDataForm({values}))
    dispatch(setSlide({ slide: 2 }))
  }


  return (
    <Form onSubmit={handleSubmit(onSubmit)} className='mt-5'>
      <Row className='d-flex justify-content-center'>
        <Col md={6}>
          <h1 className='fw-normal h6'>
            <span className='border-bottom border-primary border-2 pb-1'>Formulario de registro, tipo de inscripción</span>
          </h1>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center mt-3'>
        <Col md={3} className='mt-3'>
          <Card className='border-0 mb-5 pb-3 card-custom-style rounded-top-2 btn-cices-full-blue-v2 d-flex justify-content-center'>
            <Card.Img variant="top" src={nacional} className='mb-5 pt-4' style={{width: '140px', alignSelf: 'center'}} />
            <Card.Body className='p-4'>
              <div className="d-grid gap-2">
                  <Button className='btn-cices-v2 fw-bold' onClick={()=> handleOption({is_honduran: 'S'})}>Honduras</Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className='mt-3'>
          <Card className='border-0 mb-5 pb-3 card-custom-style rounded-top-2 btn-cices-full-blue-v2 d-flex justify-content-center'>
            <Card.Img variant="top" src={internacional} className='mb-5 pt-4' style={{width: '140px', alignSelf: 'center'}} />
            <Card.Body className='p-4'>
              <div className="d-grid gap-2">
                  <Button className='btn-cices-v2 fw-bold' onClick={()=> handleOption({is_honduran: 'N'})}>Internacional</Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Form>
  )
}
