import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { setDataForm, setDataFormChorotega, setSlide, setSlideInternal } from '../../services/registerSlice';
import ReactInputMask from 'react-input-mask';

const GENDER_OPTIONS = [
  { value: 'F', label: 'Femenino' },
  { value: 'M', label: 'Masculino' }
]

export const SlideTwo = () => {
  const dispatch = useDispatch();
  const { dataForm, isInternalRegister, dataFormChorotega } = useSelector(state => state.registerData)
  const [identityLabel, setIdentityLabel] = useState('Num. de identidad')
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    if (dataForm.is_honduran === 'N') {
      setIdentityLabel('Num. de pasaporte')
    }

  }, [dataForm, setIdentityLabel])

  useEffect(() => {
    if (isInternalRegister) {
      Object.keys(dataFormChorotega).forEach((item) => {
        setValue(item, dataFormChorotega[item])
      })
    } else {
      Object.keys(dataForm).forEach((item) => {
        setValue(item, dataForm[item])
      })
    }
  }, [dataForm, isInternalRegister, dataFormChorotega, setValue])

  const onSubmit = async (data) => {
    let values = {}
    if (isInternalRegister) {
      values = { ...dataFormChorotega, ...data }
      dispatch(setDataFormChorotega({ values }))
      dispatch(setSlideInternal({ slideInternal: 3 }))
    } else {
      values = { ...dataForm, ...data }
      dispatch(setDataForm({ values }))
      dispatch(setSlide({ slide: 3 }))
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className='mt-5'>
      <Row className='d-flex justify-content-center'>
        <Col md={5} sm={12}>
          <h1 className='fw-normal h6'>
            <span className='border-bottom border-primary border-2 pb-1'>Formulario de registro, información personal</span>
          </h1>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center mt-3'>
        <Col md={5}>
          <Form.Group className="mb-3" controlId="formGroupName">
            <Form.Label className="mb-0">{identityLabel}</Form.Label>
            {
              dataForm?.is_honduran === 'S' ?
                <>
                  <ReactInputMask
                    type='text'
                    mask={"9999-9999-99999"}
                    maskChar={" "}
                    placeholder={`Ingrese ${identityLabel} (requerido)`}
                    className='form-control'
                    {...register("identity",
                      {
                        required: `${identityLabel} es requerido`,
                        maxLength: 50
                      }
                    )}
                  />
                  {errors.identity && <p className="text-danger">{errors.identity.message}</p>}
                </>
                :
                <>
                  <Form.Control
                    type="text"
                    autoFocus
                    placeholder={`Ingrese ${identityLabel} (requerido)`}
                    {...register("identity",
                      {
                        required: `${identityLabel} es requerido`,
                        maxLength: 50
                      }
                    )}
                  />
                  {errors.identity && <p className="text-danger">{errors.identity.message}</p>}
                </>
            }
          </Form.Group>
        </Col>
        <div className="w-100"></div>
        <Col md={5}>
          <Form.Group className="mb-3" controlId="formGroupName">
            <Form.Label className="mb-0">Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder={`Ingrese Nombre (requerido)`}
              {...register("name",
                {
                  required: 'Nombre es requerido',
                  maxLength: 50
                }
              )}
            />
            {errors.name && <p className="text-danger">{errors.name.message}</p>}
          </Form.Group>
        </Col>
        <div className="w-100"></div>
        <Col md={5}>
          <Form.Group className="mb-3" controlId="formGroupLastName">
            <Form.Label className="mb-0">Apellidos</Form.Label>
            <Form.Control
              type="text"
              placeholder={`Ingrese Apellidos (requerido)`}
              {...register("last_name",
                {
                  required: 'Apellidos es requerido',
                  maxLength: 50
                }
              )}
            />
            {errors.last_name && <p className="text-danger">{errors.last_name.message}</p>}
          </Form.Group>
        </Col>
        <div className="w-100"></div>
        <Col md={5}>
          <Form.Group className="mb-3" controlId="formGroupCountry">
            <Form.Label className="mb-0">Genero</Form.Label>
            <Controller
              name='gender'
              rules={
                { required: 'Genero es requerido' }
              }
              control={control} render={({ field: { onChange, value, ref } }) => (
                <Select inputRef={ref}
                  onChange={val => onChange(val.value)}
                  value={GENDER_OPTIONS.filter(c => c.value === value)}
                  options={GENDER_OPTIONS}
                  placeholder={`Seleccione genero`}
                />
              )} />
            {errors.gender && <p className="text-danger">{errors.gender.message}</p>}
          </Form.Group>
        </Col>
        <div className="w-100"></div>
        <Col md={5} className='mt-3'>
          <div className="d-grid gap-2">
            <button className="btn btn-primary" type="submit">Continuar (2/4) <i className="bi bi-arrow-right"></i></button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}
