import React, { useEffect, useRef } from 'react'

import { Card } from 'react-bootstrap'

import logoNegative from '../assets/images/logo-negative.png'
import { useDispatch, useSelector } from 'react-redux';
import { SlideOne } from '../components/register/SlideOne';
import { SlideTwo } from '../components/register/SlideTwo';
import { SlideThree } from '../components/register/SlideThree';
import { SlideFour } from '../components/register/SlideFour';
import { useGetCountriesQuery, useGetOrganizationTypeQuery, useGetPackagesQuery } from '../services/catalogsApi';
import { Loader } from '../components/common/Loader';
import { setCountries, setIsInternalRegister, setOrganizations, setPackages } from '../services/registerSlice';
import { SlideFiveInternal } from '../components/register/SlideFiveInternal';

export const StartInternalRegisterPage = () => {
  const dispatch = useDispatch()
  const { slideInternal } = useSelector(state => state.registerData)
  const { data: countries, isLoading } = useGetCountriesQuery();
  const { data: packages, isLoading: isLoadingPackages } = useGetPackagesQuery({is_turistic: 'N'});
  const { data: organizations, isLoading: isLoadingOrganizations } = useGetOrganizationTypeQuery();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    if(!!packages){
      dispatch(setPackages({packages}))
    }
  }, [packages, dispatch])

  useEffect(() => {
    if(!!countries){
      dispatch(setCountries({countries}))
    }
  }, [countries, dispatch])

  useEffect(() => {
    if(!!organizations){
      dispatch(setOrganizations({organizations}))
    }
  }, [organizations, dispatch])

  useEffect(() => {
    dispatch(setIsInternalRegister ({value: true}))
  }, [dispatch])

  if(isLoading || isLoadingPackages || isLoadingOrganizations) return <Loader/>
  return (
    <div className={windowSize.current[0] < 1000 ? 'container-fluid' : 'container'}>
      <Card className=' border-0 mt-2 mb-5 pb-3 card-custom-style rounded-top-2'>
        <div className="cices-blue-bgcolor cices-border-bottom-golden div-header-card-cices rounded-top-2">
          <img src={logoNegative} alt="CICES 2024" id='logoCICESRegister' className='py-3' />
        </div>
        <Card.Body>
          {
              slideInternal === 1 ? <SlideOne/>
            : slideInternal === 2 ? <SlideTwo/>
            : slideInternal === 3 ? <SlideThree/>
            : slideInternal === 4 ? <SlideFour/>
            : <SlideFiveInternal/>
          }
        </Card.Body>
      </Card>
    </div>
  )
}
