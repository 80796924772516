import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useGetRegisterDetailQuery } from '../services/registerApi'
import { Loader } from '../components/common/Loader'
import { PaymentOptions } from '../components/detail/ProcessPayment/PaymentOptions'
import { useDispatch } from 'react-redux'
import { setRegisteredData } from '../services/registerSlice'
import { RegisterConferences } from '../components/detail/Conferences/RegisterConferences'
import { NotFound } from './NotFound'

export const RegisterDetail = () => {
  const dispatch = useDispatch()
  const {id} = useParams()
  const {data, isLoading, error} = useGetRegisterDetailQuery(id)
  useEffect(() => {
    if(!!data){
      dispatch(setRegisteredData({data}))
    }
  }, [data, dispatch])

  if(isLoading) return <Loader/>
  if(error) return <NotFound/>

  return (
    <>
      {
        data.status === '1' && data.in_stand_by !== 'S' ? <PaymentOptions data={data}/>
        : data.status === '2' ? <RegisterConferences data={data}/>
        : <></>
      }
    </>
  )
}
