import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setPackageTuristicSelected, setSlide } from '../../services/registerSlice';
import { useGetPackagesParentsQuery } from '../../services/catalogsApi';

export const SlideSix = () => {

  const dispatch = useDispatch();
  const { packageSelected } = useSelector(state => state.registerData)
  const [turisticPackage, setTuristicPackage] = useState([])
  const { data: packages, isLoading: isLoadingPackages } = useGetPackagesParentsQuery({ package_parent_id: packageSelected.id });

  useEffect(() => {
    const pckgs = packages?.filter(el => el.currency === packageSelected.currency && el.is_turistic === 'S')
    setTuristicPackage(pckgs)
  }, [packages, packageSelected])

  const handleSelected = (pckg) => {
    dispatch(setPackageTuristicSelected({ data: pckg }))
    dispatch(setSlide({ slide: 7 }))
  }

  const handleContinue = () => {
    dispatch(setPackageTuristicSelected({ data: null }))
    dispatch(setSlide({ slide: 7 }))
  }

  if (isLoadingPackages) {
    return <></>
  }

  return (
    <>
      <Row className='d-flex justify-content-center mt-4'>
        <Col md={6} sm={8} xs={10}>
          <h1 className='fw-normal h6'>
            <span className='border-bottom border-primary border-2 pb-1'>Realizar turísmo con proposito</span>
          </h1>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center mt-3'>
        {

          turisticPackage?.length === 1 ?
            turisticPackage?.map((el, idx) => (
              <Col xl={6} lg={6} md={10} sm={12} xs={12} className='mb-4' key={`turisticPackage_${idx}`}>
                <Card className='cices-packages-card border-0' >
                  <Row className='g-0'>
                    <Col md={6}>
                      <Card.Img src={el.image} alt="Card image" className='img-fluid rounded-start' style={{height: '100%'}} loading='lazy'></Card.Img>
                    </Col>
                    <Col md={6}>
                      <Card.Body>
                        <Row>
                          <Col md={12}>
                            <Card.Title className='text-color-cices-blue fw-bold'>{el.name}</Card.Title>
                          </Col>
                          <Col md={12}>
                            <h6 style={{ color: 'rgb(88 85 85)' }}>{el.amount.toLocaleString("en-US")} {el.currency}</h6>
                          </Col>
                        </Row>
                        <Card.Text style={{ fontSize: '12px' }}>{el.description}</Card.Text>
                        <div className="d-grid gap-2">
                          <button className='btn btn-cices-full-blue fw-bold fs-6 mx-2' onClick={() => handleSelected(el)}>
                            Participar
                          </button>
                        </div>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))
            :
            turisticPackage?.map((el, idx) => (
              <Col md={3} sm={8} xs={10} className='mb-4' key={`turisticPackage_${idx}`}>
                <Card className='cices-packages-card border-0' style={{ minHeight: '480px' }}>
                  <Card.Img src={el.image} alt="Card image" loading='lazy'></Card.Img>
                  <Card.Body>
                    <Row>
                      <Col md={6}>
                        <Card.Title className='text-color-cices-blue fw-bold'>{el.name}</Card.Title>
                      </Col>
                      <Col md={6}>
                        <h6 style={{ color: 'rgb(88 85 85)' }}>{el.amount.toLocaleString("en-US")} {el.currency}</h6>
                      </Col>
                    </Row>
                    <Card.Text style={{ fontSize: '12px', minHeight: '150px' }}>{el.description}</Card.Text>

                    <div className="d-grid gap-2">
                      <button className='btn btn-cices-full-blue fw-bold fs-6 mx-2' onClick={() => handleSelected(el)}>
                        Participar
                      </button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
        }

        <div className="w-100"></div>
        <Col md={5}>
          <Row className='d-flex justify-content-center mt-3 pb-2'>
            <Col md={12}>
              <div className="d-grid gap-2">
                <button className='btn btn-link fw-bold' onClick={handleContinue}>Continuar sin viaje turistico</button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
